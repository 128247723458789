import { Combobox, Input, InputBase, useCombobox } from '@mantine/core'
import { CustomSelectProps } from './CustomSelect.interfaces'
import styles from './CustomSelect.module.css'
import { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

function CustomSelect({ options, placeholder, onSelect, value }: CustomSelectProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value)

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  })

  const boxOptions = options.map((item) => (
    <Combobox.Option value={item.value} key={item.value}>
      {item.label}
    </Combobox.Option>
  ))

  const handleSelect = (val: string) => {
    setSelectedValue(val)
    onSelect?.(val)
    combobox.closeDropdown()
  }

  return (
    <Combobox store={combobox} onOptionSubmit={handleSelect} data-testid="custom-select">
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          data-testid="custom-select-button"
          classNames={{ input: styles.Input }}
          pointer
          rightSection={
            combobox.dropdownOpened ? (
              <ChevronUpIcon className={styles.Icon} />
            ) : (
              <ChevronDownIcon className={styles.Icon} />
            )
          }
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}
        >
          {selectedValue ? (
            options.find((item) => item.value === selectedValue)?.label
          ) : (
            <Input.Placeholder>{placeholder}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{boxOptions}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default CustomSelect
