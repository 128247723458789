import getNotifications from './getNotifications/getNotifications'
import markAllNotificationRead from './markAllNotificationRead/markAllNotificationRead'
import markSpecificNotificationRead from './markSpecificNotificationRead/markSpecificNotificationRead'
import getAuditReportsAgainstRandomString from './getAuditReportsAgainstRandomString/getAuditReportsAgainstRandomString'
import getRealTimeAuditReports from './getRealTimeAuditReports/getRealTimeAuditReports'
export default {
  getNotifications,
  markAllNotificationRead,
  markSpecificNotificationRead,
  getAuditReportsAgainstRandomString,
  getRealTimeAuditReports
}
