import { createSlice } from '@reduxjs/toolkit'
import { IAuditRuleSetState } from './auditRuleSet.interfaces'
const initialState: IAuditRuleSetState = {
  auditRuleSet: {
    auditRuleSet: [],
    auditRuleSetAll: [],
    auditRuleSetAssign: []
  }
}
const auditRuleSet = createSlice({
  name: 'auditRuleSet',
  initialState,
  reducers: {
    setAuditRuleSet(state, action) {
      state.auditRuleSet.auditRuleSet = action.payload?.data
    },
    setAuditRuleSetAll(state, action) {
      state.auditRuleSet.auditRuleSetAll = action.payload?.data
    },
    setAuditRuleSetAssign(state, action) {
      state.auditRuleSet.auditRuleSetAssign = action.payload?.data
    }
  }
})

export const { setAuditRuleSet, setAuditRuleSetAll, setAuditRuleSetAssign } = auditRuleSet.actions

export default auditRuleSet.reducer
