'use client'

import { DataTable as MantineDataTable } from 'mantine-datatable'
import styles from './DataTable.module.css'
import { Stack, Text, useMantineTheme } from '@mantine/core'
import { TableProps } from './DataTable.interfaces'
import { ChevronUpDownIcon, ChevronUpIcon, CircleStackIcon } from '@heroicons/react/24/outline'

function DataTable<T>({
  columns = [],
  data = [],
  emptyDataMessage = 'No data found...',
  isLoading = false,
  ...props
}: TableProps<T>) {
  const { colors } = useMantineTheme()

  return (
    <MantineDataTable
      shadow="md"
      fetching={isLoading}
      minHeight={data?.length ? 'auto' : 260}
      withTableBorder={false}
      borderRadius="md"
      withColumnBorders
      borderColor={{ dark: colors.secondary[1], light: colors.secondary[7] }}
      classNames={{ header: styles.Header, table: styles.Table, pagination: styles.Pagination, root: styles.Root }}
      columns={columns}
      records={data}
      emptyState={
        <Stack align="center" gap="xs">
          <CircleStackIcon className={styles.DataIcon} />
          <Text c="primary" size="sm">
            {emptyDataMessage}
          </Text>
        </Stack>
      }
      sortIcons={{
        sorted: <ChevronUpIcon className={styles.SortedIcon} />,
        unsorted: <ChevronUpDownIcon className={styles.SortIcon} />
      }}
      {...props}
    />
  )
}

export default DataTable
