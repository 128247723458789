import { requests } from '@/libs/axios/axios'
import { NotificationEndPoints } from '../notification-endpoints'
import { AxiosResponse } from 'axios'
const getNotifications = async (id: number): Promise<AxiosResponse> => {
  return requests.put(
    `${NotificationEndPoints.BASE_NOTIFICATION}/${id}${NotificationEndPoints.UPDATE_NOTIFICATION_READ}`,
    {}
  )
}
export default getNotifications
