import { SESSION_TOKENS } from '@/constants/SESSION_TOKENS'
import authApi from '@/services/auth/auth.api'
import { setLogout, setToken, setTermOfServices } from '@/store/slices/auth/auth.slice'
import { setNotification } from '@/store/slices/notification/notification.slice'
import { Store } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import Cookies from 'js-cookie'

let store: Store
let isRefreshing = false
let failedQueue: Array<{ resolve: (token: string) => void; reject: (error: AxiosError | Error | null) => void }> = []

const processQueue = (error: AxiosError | Error | null, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      token && prom.resolve(token)
    }
  })
  failedQueue = []
}

async function addToFailedQueue() {
  return new Promise<string>((resolve, reject) => {
    failedQueue.push({ resolve, reject })
  })
}

export const injectStore = (_store: Store) => {
  store = _store
}

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

export function axiosWithInterceptors(axiosInstance: AxiosInstance) {
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function (config): AdaptAxiosRequestConfig {
      // Do something before request is sent
      const accessToken = store.getState().auth.token
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

      return config
    },
    async function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    async function (error) {
      const originalRequest = error.config

      if (axios.isAxiosError(error)) {
        console.log(error, 'error error error')
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response?.status === 401 && !originalRequest._retry) {
          // Unauthorized
          console.log('Unauthorized to access the resource')
          originalRequest._retry = true

          if (isRefreshing) {
            try {
              const token = await addToFailedQueue()
              originalRequest.headers.Authorization = `Bearer ${token}`
              return axiosInstance(originalRequest)
            } catch (error) {
              return Promise.reject(error)
            }
          }

          isRefreshing = true

          const refreshToken = Cookies.get(SESSION_TOKENS.REFRESH)
          const username = Cookies.get('username')

          if (refreshToken && !originalRequest.url?.includes('refresh') && !originalRequest.url?.includes('login')) {
            try {
              const response = await authApi.refresh(refreshToken, username)
              console.log('Refreshed token')
              const { AuthenticationResult, TermOfService } = response.data
              const { AccessToken } = AuthenticationResult
              store.dispatch(setToken(AccessToken))
              store.dispatch(setTermOfServices(TermOfService))
              originalRequest.headers.Authorization = `Bearer ${AccessToken}`
              processQueue(null, AccessToken)
              return axiosInstance(originalRequest)
            } catch (error) {
              console.error('Failed to refresh token')
              processQueue(error as AxiosError, null)
              store.dispatch(setLogout())
              store.dispatch(setNotification({ message: 'Session expired. Please login again', variant: 'error' }))
              return Promise.reject(error)
            } finally {
              isRefreshing = false
            }
          } else {
            store.dispatch(setNotification({ message: 'Session expired. Please login again', variant: 'error' }))
            store.dispatch(setLogout())
          }
        }
        // else if (error.request) {
        //   // The request was made but no response was received
        //   console.log(error.request)
        // }
        else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )

  return axiosInstance
}
