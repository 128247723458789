import { createAsyncAction } from '../utils/createAsyncAction'
import { setReports, setReport } from '../slices/reports/reports.slice'
import { setNotification } from '../slices/notification/notification.slice'
import { AxiosError } from 'axios'
import { XMLParser } from 'fast-xml-parser'
import reportsApi from '@/services/reports/reports.api'
import getErrorMessage from '@/utils/getErrorMessage'
import { ReportsEndpoints } from '@/services/reports/reportsEndpoints'
import {
  setReportGenerating,
  setReportsLoading,
  setReportLoading,
  setReportUploading,
  setReportUpdating
} from '../slices/loaders/report-loaders/report-loaders.slice'
import { getCurrentUserLicense } from '@/store/actions/license.actions'

export const getReports = createAsyncAction<unknown, string | undefined>(
  'reports/getReports',
  async (filters, { dispatch }) => {
    dispatch(setReportsLoading(true))

    try {
      const response = await reportsApi.getReports(filters)

      dispatch(setReports(response.data))
    } catch (error) {
      const message = getErrorMessage(error)

      dispatch(
        setNotification({
          message: `Failed to get the reports. Error: ${message}`,
          variant: 'error'
        })
      )
    } finally {
      dispatch(setReportsLoading(false))
    }
  }
)

export const generateReport = createAsyncAction<unknown, { reportId: string }>(
  'reports/generateReport',
  async ({ reportId }, { dispatch }) => {
    dispatch(setReportGenerating(true))

    try {
      const response = await reportsApi.generateReport(reportId)
      console.log(response)
      const blob = new Blob([base64ToArrayBuffer(response.data)], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      dispatch(setNotification({ message: 'Report generated successfully', variant: 'success' }))
    } catch (error) {
      const message = getErrorMessage(error)

      dispatch(
        setNotification({
          message: `Failed to generate the report. Error: ${message}`,
          variant: 'error'
        })
      )
    } finally {
      dispatch(setReportGenerating(true))
    }
  }
)

export const getReportResult = createAsyncAction<unknown, { reportId: number }>(
  'reports/getReportResult',
  async ({ reportId }, { dispatch }) => {
    dispatch(setReportLoading(true))

    try {
      const response = await reportsApi.getReportResult(reportId)

      dispatch(setReport(response.data))
    } catch (error) {
      const message = getErrorMessage(error)

      dispatch(
        setNotification({
          message: `Failed to get the report result. Error: ${message}`,
          variant: 'error'
        })
      )
    } finally {
      dispatch(setReportLoading(false))
    }
  }
)

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export const throwReportError = createAsyncAction<unknown, { errorMessage: string }>(
  'reports/reportError',
  async ({ errorMessage }, { dispatch, rejectWithValue }) => {
    dispatch(
      setNotification({
        message: errorMessage,
        variant: 'error'
      })
    )
    rejectWithValue(errorMessage)
  }
)

export const uploadReport = createAsyncAction<
  unknown,
  {
    file: File
    orgId: string
    fileName: string
    company: string
    isBulkUpload: boolean
    randomString: string
    type: string
  }
>('reports/uploadReport', async (values, { dispatch, getState }) => {
  const formData = new FormData()
  const currentOrgId = getState()?.auth.user?.organization.id
  try {
    formData.append('file', values.file)
    formData.append('fileName', values.fileName)
    formData.append('companyName', values.company)
    formData.append('randomString', values.randomString)
    formData.append('type', values.type)
    const url = `/api/v1/organizations/${currentOrgId}${ReportsEndpoints.UPLOAD_FILE}`

    const response = await reportsApi.saveReportToUrl(url, formData)
    if (!values?.isBulkUpload) {
      dispatch(setNotification({ message: 'Report uploaded successfully', variant: 'success' }))
      dispatch(getReports('columnAccessor=id&direction=DESC'))
      dispatch(getCurrentUserLicense())
    }

    return response
  } catch (error) {
    console.log(error)

    const message = getErrorMessage(error)
    dispatch(setNotification({ message, variant: 'error' }))

    // Parses AWS Error, which comes in XML format, to a String to display the message
    const e = error as AxiosError
    const xmlParser = new XMLParser()
    const AwsErrorMessage: string = e.response?.data as string
    const AwsErrorXml = xmlParser.parse(AwsErrorMessage)

    dispatch(
      setNotification({
        message: `The Upload failed with the following error: ${AwsErrorXml.Error.Message}`,
        variant: 'error'
      })
    )
  } finally {
    dispatch(setReportUploading(false))
  }
})

export const changeFileName = createAsyncAction<unknown, { reportId: number; fileName: string }>(
  'reports/changeFileName',
  async (values, { dispatch }) => {
    dispatch(setReportUpdating(true))

    try {
      const body = { name: values.fileName }
      await reportsApi.updateAuditData(values.reportId.toString(), body)

      dispatch(setNotification({ message: 'Filename changed successfully', variant: 'success' }))
      dispatch(getReports())
    } catch (error) {
      console.log(error)

      // Parses AWS Error, which comes in XML format, to a String to display the message
      const e = error as AxiosError
      const xmlParser = new XMLParser()
      const AwsErrorMessage: string = e.response?.data as string
      const AwsErrorXml = xmlParser.parse(AwsErrorMessage)

      dispatch(
        setNotification({
          message: `The Filename change failed with the following error: ${AwsErrorXml.Error.Message}`,
          variant: 'error'
        })
      )
    } finally {
      dispatch(setReportUpdating(false))
    }
  }
)

export const updateActionTaken = createAsyncAction<unknown, { reportId: number; action: string }>(
  'reports/updateActionTaken',
  async (values, { dispatch }) => {
    dispatch(setReportUpdating(true))

    try {
      const body = { action_taken: values.action }
      await reportsApi.updateAuditData(values.reportId.toString(), body)

      dispatch(setNotification({ message: 'Updated Action Taken', variant: 'success' }))
      dispatch(getReports())
    } catch (error) {
      console.log(error)

      // Parses AWS Error, which comes in XML format, to a String to display the message
      const e = error as AxiosError
      const xmlParser = new XMLParser()
      const AwsErrorMessage: string = e.response?.data as string
      const AwsErrorXml = xmlParser.parse(AwsErrorMessage)

      dispatch(
        setNotification({
          message: `The Filename change failed with the following error: ${AwsErrorXml.Error.Message}`,
          variant: 'error'
        })
      )
    } finally {
      dispatch(setReportUpdating(false))
    }
  }
)
