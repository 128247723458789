import { useMantineColorScheme } from '@mantine/core'
import { SpinnerProps } from './Spinner.interfaces'
import styles from './Spinner.module.css'
import SpinnerImg from '@/assets/images/icons/spinner.svg'
import SpinnerImgDark from '@/assets/images/icons/spinner-dark.svg'
import { ColorSchemes } from '@/enums/colorSchemes'

function Spinner({ width }: SpinnerProps) {
  const { colorScheme } = useMantineColorScheme()

  const spinner = colorScheme === ColorSchemes.DARK ? SpinnerImgDark : SpinnerImg

  return (
    <div className={styles.Spinner} style={{ width, height: width }} data-testid="spinner">
      <img src={spinner} alt="Luminous spinner" />
    </div>
  )
}

export default Spinner
