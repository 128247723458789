import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationData, NotificationDataState } from './notificationData.interfaces'

const initialState: NotificationDataState = {
  notifications: [],
  total: 0,
  unreadCount: 0,
  notificationAuditReports: []
}

const notificationDataSlice = createSlice({
  name: 'notificationData',
  initialState,
  reducers: {
    // Action payload should include both notifications and total
    setNotificationData(
      state,
      action: PayloadAction<{ notifications: NotificationData[]; total: number; unreadCount: number }>
    ) {
      state.notifications = [...state.notifications, ...action.payload.notifications]
      state.total = action.payload.total
      state.unreadCount = action.payload.unreadCount
    },
    setNotificationAuditReportsData(state, action) {
      state.notificationAuditReports = action.payload?.notificationAuditReports?.audits
    },
    clearNotificationData: () => {
      return initialState
    }
  }
})

export const { setNotificationData, clearNotificationData, setNotificationAuditReportsData } =
  notificationDataSlice.actions

export default notificationDataSlice.reducer
