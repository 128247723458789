import { Group, Text, Card, Divider, Menu, useMantineTheme } from '@mantine/core'
import styles from './NotificationMenu.module.css'
import { useNavigate } from 'react-router-dom'
import { NotificationData } from '@/store/slices/notificationData/notificationData.interfaces'
import { KeyIcon, Square2StackIcon, Square3Stack3DIcon, BellIcon, BellAlertIcon } from '@heroicons/react/24/outline'
import { parseISO, format, formatDistanceToNow } from 'date-fns'
import { useAppDispatch, useAppSelector } from '@/store/hooks/store-hooks'
import {
  markAllRead,
  markSpecificNotificationRead,
  getAuditReportsAgainstRandomKey
} from '@/store/actions/notification.action'
import { clearModal, openModal } from '@/store/slices/modal/modal.slice'
import { useState } from 'react'
import Modal from '@/components/atoms/Modal/Modal'
import DataTable from '@/components/atoms/DataTable/DataTable'
import { useTableData, PAGE_SIZES } from './../../templates/ReportsTemplate/hooks/useTableData.hook'
import { DataTableSortStatus } from 'mantine-datatable'
import { ReportContract } from '@/contracts/Report.contract'
import Button from '../Button/Button'
function NotificationMenu({ ...props }) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { colors } = useMantineTheme()
  const [modalType, setModalType] = useState<string | null>(null)
  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  const { title } = useAppSelector((state) => state.modal)
  const reports = useAppSelector((state) => state?.notificationData)
  const { columns, reportss } = useTableData(reports?.notificationAuditReports, false)
  const [sortingData, setSortingData] = useState<DataTableSortStatus<ReportContract>>({
    columnAccessor: 'id',
    direction: 'desc'
  })
  const dateConversion = (date: string): { formattedDate: string; relativeTime: string } => {
    try {
      const originalDate = parseISO(date)
      if (isNaN(originalDate.getTime())) {
        throw new Error('Invalid date')
      }
      const formattedDate = format(originalDate, 'EEEE h:mm a')
      const relativeTime = formatDistanceToNow(originalDate, { addSuffix: true })
      return { formattedDate, relativeTime }
    } catch (error) {
      return { formattedDate: 'Invalid date', relativeTime: 'Invalid date' }
    }
  }
  const handleClickMarkAllRead = async () => {
    await dispatch(markAllRead())
  }
  const handleClickMarkRead = async (id: number) => {
    await dispatch(markSpecificNotificationRead(id))
  }
  const handleClickNotification = async (data: NotificationData) => {
    await dispatch(markSpecificNotificationRead(data?.id))
    if (data?.type === 'singleReport') {
      setMenuOpened(false)
      navigate(`/report-analysis?reportId=${data?.audit_id}`)
    }
    if (data?.type === 'bulkReport') {
      dispatch(clearModal())
      setMenuOpened(false)
      setModalType('bulkReportNotification')
      dispatch(openModal({ title: 'Bulk Audit Reports' }))
      await dispatch(getAuditReportsAgainstRandomKey(data?.random_string))
    }
    if (data?.type === 'license') {
      setMenuOpened(false)
      navigate(`/license-management`)
    }
  }
  const handleRecordsPerPage = (recordsPerPage: number) => {
    console.log(recordsPerPage, 'recordsPerPage')
  }
  const handlePageChange = (recordsPerPage: number) => {
    console.log(recordsPerPage, 'recordsPerPage')
  }
  const handleSortData = (data: DataTableSortStatus<ReportContract>) => {
    console.log(data, 'handleSortData')
    setSortingData(data)
  }
  const handleCloseModal = () => {
    setModalType(null)
  }
  return (
    <>
      <Menu shadow="md" width={400} data-testid="user-menu" opened={menuOpened} onClose={() => setMenuOpened(false)}>
        <div onClick={() => setMenuOpened((prev) => !prev)} data-testid="menu-trigger">
          <Menu.Target>
            {props.notificatioData?.unreadCount === 0 ? (
              <BellIcon style={{ cursor: 'pointer' }} className={styles.iconRead} />
            ) : (
              <div style={{ position: 'relative', marginTop: '5px', cursor: 'pointer' }}>
                <BellAlertIcon className={styles.iconRead} />
                <div className={styles.unreadNotificationCount}>{props.notificatioData?.unreadCount}</div>
              </div>
            )}

            {/* <img src={NotificationIcon} /> */}
          </Menu.Target>
        </div>
        <Menu.Dropdown className={styles.Dropdown} data-testid="menu-dropdown">
          <Card radius="md" padding="sm" style={{ height: '450px', overflow: 'auto' }}>
            <Group justify="space-between">
              <Text size="lg" style={{ fontWeight: '600' }}>
                Notifications
              </Text>
              <Text
                style={{ cursor: 'pointer' }}
                size="xs"
                color="#0194d3"
                onClick={() => {
                  handleClickMarkAllRead()
                }}
              >
                Mark all as read
              </Text>
            </Group>

            <Divider />
            <div>
              {props?.notificatioData?.notifications?.map((item: NotificationData, index: number) => (
                <div key={`${item.id}-${index}`}>
                  <div
                    className={
                      item.status === 'read'
                        ? styles.singleNotificationContainer
                        : styles.singleNotificationContainerUnread
                    }
                    style={{ marginTop: '3px' }}
                  >
                    <div
                      className={styles.singleNotificationInnerContainer}
                      onClick={() => {
                        handleClickNotification(item)
                      }}
                    >
                      <div style={{ width: '30px', display: 'flex' }}>
                        {item?.type === 'license' && <KeyIcon className={styles.iconRead} />}
                        {item?.type === 'singleReport' && <Square2StackIcon className={styles.iconRead} />}
                        {item?.type === 'bulkReport' && <Square3Stack3DIcon className={styles.iconRead} />}
                      </div>
                      <div style={{ width: '100%' }}>
                        <Text size="sm">
                          <Text component="span"></Text> {item.message}
                        </Text>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                          <Text size="xs" color="dimmed">
                            {dateConversion(item?.createdAt)?.formattedDate}
                          </Text>
                          <Text size="xs" color="dimmed">
                            {dateConversion(item?.createdAt)?.relativeTime}
                          </Text>
                        </div>
                      </div>
                    </div>
                    {item.status === 'unread' && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '5px',
                          gap: '10px',
                          alignItems: 'center'
                        }}
                      >
                        <Text
                          style={{ cursor: 'pointer' }}
                          size="xs"
                          color="#0194d3"
                          onClick={() => {
                            handleClickMarkRead(item?.id)
                          }}
                        >
                          Mark as read
                        </Text>
                      </div>
                    )}
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
            {props?.notificatioData?.notifications?.length !== props?.notificatioData?.total && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Button label="Load More" outlined onClick={() => props.loadMore()} />
              </div>
            )}
          </Card>
        </Menu.Dropdown>
      </Menu>
      {modalType === 'bulkReportNotification' && title === 'Bulk Audit Reports' && (
        <Modal onClose={handleCloseModal}>
          <DataTable
            isLoading={false}
            columns={columns}
            data={reportss}
            totalRecords={reportss?.length}
            recordsPerPage={100}
            page={1}
            onPageChange={handlePageChange}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={handleRecordsPerPage}
            paginationSize="md"
            paginationActiveTextColor={{ dark: colors.primary[9], light: 'white' }}
            paginationActiveBackgroundColor={{ dark: colors.secondary[1], light: colors.secondary[9] }}
            sortStatus={sortingData}
            onSortStatusChange={handleSortData}
            emptyDataMessage="No reports found"
          />
        </Modal>
      )}
    </>
  )
}

export default NotificationMenu
