export interface ReportsContractRoot {
  audits: ReportContract[]
  totalCount?: number
}
export interface ReportContract {
  documentName: string
  id: number
  link: string
  reportResult: string
  status: 'pending' | 'warning' | 'done' | 'error' | 'invalid' | 'outOfCompliance'
  uploadedBy: string
  lastViewedAt: string
  uploadedDate: string
  hasBeenDownloaded: boolean
  actionTaken: string
  hasBeenViewed: boolean
  audits?: ReportContract[]
}

export const enum ActionsTakenEnum {
  OpenCase = 'open_case',
  ClosedCase = 'closed_case',
  ContactedEnergyCompany = 'contacted_energy_company',
  ContactedTestingCompany = 'contacted_testing_company'
}

export const enum ReportStatusEnum {
  Pending = 'pending',
  Done = 'done',
  Invalid = 'invalid',
  Warning = 'warning',
  Error = 'error'
}

export interface ReportContractStatus {
  data: {
    actionTaken?: 'open_case' | 'closed_case' | 'contacted_energy_company'
    documentName?: string
    id?: number
    link?: string
    reportResult?: string
    status?: 'pending' | 'warning' | 'done' | 'error' | 'invalid'
    uploadedBy?: string
    lastViewedAt?: string
    uploadedDate?: string
    hasBeenDownloaded?: boolean
    hasBeenViewed?: boolean
    document_name?: string
  }
}
