import notificationApi from '@/services/notification/notification.api'
import getErrorMessage from '@/utils/getErrorMessage'
import {
  setNotificationData,
  setNotificationAuditReportsData,
  clearNotificationData
} from '../slices/notificationData/notificationData.slice'
import { setNotification } from '../slices/notification/notification.slice'
import { AppDispatch } from '../store'

export const getNotification = (offset: number, limit: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await notificationApi.getNotifications(offset, limit)
    if (response?.data) {
      // dispatch(setNotificationData(response?.data))
      dispatch(
        setNotificationData({
          notifications: response.data.data,
          total: response.data.total,
          unreadCount: response.data.unreadCount
        })
      )
      return response?.data
    }

    return {
      statusText: 'success',
      data: response
    }
  } catch (error) {
    const message = getErrorMessage(error)
    return {
      statusText: 'error',
      data: message
    }
  }
}
export const markAllRead = () => async (dispatch: AppDispatch) => {
  try {
    const response = await notificationApi.markAllNotificationRead()
    dispatch(clearNotificationData())
    dispatch(getNotification(0, 10))
    if (response?.data) {
      return response?.data
    }
    return {
      statusText: 'success',
      data: response
    }
  } catch (error) {
    const message = getErrorMessage(error)
    return {
      statusText: 'error',
      data: message
    }
  }
}
export const markSpecificNotificationRead = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await notificationApi.markSpecificNotificationRead(id)
    dispatch(clearNotificationData())
    dispatch(getNotification(0, 10))
    return response?.data
  } catch (error) {
    const message = getErrorMessage(error)
    return {
      statusText: 'error',
      data: message
    }
  }
}
export const getAuditReportsAgainstRandomKey = (randomString: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await notificationApi.getAuditReportsAgainstRandomString(randomString)
    dispatch(
      setNotificationAuditReportsData({
        notificationAuditReports: response.data
      })
    )
    return response?.data
  } catch (error) {
    const message = getErrorMessage(error)
    return {
      statusText: 'error',
      data: message
    }
  }
}
export const getRealTimeNotification = () => async (dispatch: AppDispatch) => {
  try {
    const response = await notificationApi.getRealTimeAuditReports()
    if (response.status === 200) {
      // Extract the SSE URL from the response (if it's provided in the response)
      const { sseUrl } = response.data
      console.log(sseUrl, 'sseUrlsseUrlsseUrl ')
      if (!sseUrl) {
        console.error('SSE URL not found in the response')
        return
      }

      const eventSource = new EventSource(sseUrl)
      eventSource.onmessage = (event) => {
        console.log(event, 'eventeventeventevent')
        const data = JSON.parse(event.data)
        console.log(data, 'eventeventeventevent data')
        dispatch(
          setNotification({
            message: data?.message,
            variant: 'success'
          })
        )
        dispatch(getNotification(0, 10))
        console.log('Real-time Notification: eventeventeventevent', data.message)
      }

      eventSource.onerror = (error) => {
        console.error('Error with SSE connection: eventeventeventevent', error)
        eventSource.close()
      }

      // return () => {
      //   eventSource.close();
      // };
    } else {
      console.error('Failed to authenticate SSE request')
      return { statusText: 'error', data: 'Failed to authenticate SSE request' }
    }
  } catch (error) {
    const message = getErrorMessage(error)
    return {
      statusText: 'error',
      data: message
    }
  }
}
