import Spinner from '@/components/atoms/Spinner/Spinner'
import { ActionsTakenEnum, ReportContract } from '@/contracts/Report.contract'
import { DocumentArrowDownIcon, EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { ActionIcon, Text, Tooltip } from '@mantine/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '@/store/hooks/store-hooks'
import { generateReport, updateActionTaken } from '@/store/actions/reports.actions'
import { useNavigate } from 'react-router-dom'
import { NAV_LINKS } from '@/enums/navLinks'
import { CheckCircleIcon, ExclamationTriangleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import CustomSelect from '@/components/atoms/CustomSelect/CustomSelect'
import { setReport } from '@/store/slices/reports/reports.slice'
import styles from '../ReportsTemplate.module.css'
import { DataTableColumn, DataTableSortStatus } from 'mantine-datatable'
import { clearModal, closeModal, openModal } from '@/store/slices/modal/modal.slice'

export type ModalTypes = 'filters' | 'upload' | 'nameChange' | null

export const PAGE_SIZES = [10, 20, 50]

export function useTableData(reportss: ReportContract[] = [], isEdit = true) {
  const navigate = useNavigate()
  const [modalType, setModalType] = useState<ModalTypes>(null)
  const dispatch = useAppDispatch()
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0])
  const [page, setPage] = useState(1)
  const [setData] = useState<ReportContract[]>(reportss)
  const [selectedReport, setSelectedReport] = useState<ReportContract | undefined>(undefined)
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<ReportContract>>({
    columnAccessor: '',
    direction: 'asc'
  })

  useEffect(() => {
    setPage(1)
  }, [pageSize])

  // useEffect(() => {
  //   setData(reports)
  // }, [page, pageSize, reports, sortStatus])

  const onViewReport = useCallback(
    (report: ReportContract) => {
      dispatch(closeModal())
      setReport(report)
      navigate(`${NAV_LINKS.REPORT_ANALYSIS}?reportId=${report.id}`)
    },
    [navigate, dispatch]
  )

  const onDownloadReport = useCallback(
    async (report: ReportContract) => {
      try {
        await dispatch(generateReport({ reportId: String(report.id) })).unwrap()
      } catch (error) {
        console.log(error)
      }
    },
    [dispatch]
  )

  const onChangeName = useCallback(
    (report: ReportContract) => {
      dispatch(clearModal())
      setReport(report)
      setModalType('nameChange')
      dispatch(openModal({ title: 'Change Report Name' }))
      setSelectedReport(report)
    },
    [dispatch]
  )

  const onActionTaken = useCallback(
    async (reportId: number, action: string) => {
      try {
        await dispatch(updateActionTaken({ reportId, action })).unwrap()
      } catch (error) {
        console.log(error)
      }
    },
    [dispatch]
  )

  const columns: Array<DataTableColumn<ReportContract>> = useMemo(
    () => [
      {
        accessor: 'documentName',
        title: 'Report Name',
        width: '30%',
        sortable: false,
        cellsClassName: styles.NameCell,
        render: (report) => (
          <div className={styles.NameWrapper}>
            <Text>{report.documentName}</Text>
            {isEdit && (
              <ActionIcon variant="transparent" title="Change Name" onClick={() => onChangeName(report)}>
                <PencilSquareIcon className={`${styles.ActionIcon} ${styles.EditNameActionIcon}`} />
              </ActionIcon>
            )}
          </div>
        )
      },
      {
        accessor: 'actionTaken',
        title: 'Action Taken',
        width: 260,
        render: ({ id, actionTaken }) => (
          <CustomSelect
            options={[
              {
                value: ActionsTakenEnum.OpenCase,
                label: (
                  <Text fw="bold" className={`${styles.OpenCaseSelect}`}>
                    Open Case
                  </Text>
                )
              },
              {
                value: ActionsTakenEnum.ContactedEnergyCompany,
                label: (
                  <Text fw="bold" className={`${styles.ContactedEnergyCompanySelect}`}>
                    Contacted Energy Company
                  </Text>
                )
              },
              {
                value: ActionsTakenEnum.ContactedTestingCompany,
                label: (
                  <Text fw="bold" className={`${styles.ContactedTestingCompanySelect}`}>
                    Contacted Testing Company
                  </Text>
                )
              },
              {
                value: ActionsTakenEnum.ClosedCase,
                label: (
                  <Text fw="bold" className={`${styles.ClosedCaseSelect}`}>
                    Closed Case
                  </Text>
                )
              }
            ]}
            value={actionTaken}
            onSelect={async (value) => onActionTaken(id, value)}
          />
        )
      },
      {
        accessor: 'status',
        title: 'Results',
        width: 100,
        cellsClassName: styles.ResultsCell,
        render: ({ status }) => (
          <div className={styles.ActionsWrapper}>
            {status === 'pending' && <Spinner width={34} />}
            {status === 'done' && (
              <Tooltip label="Done">
                <CheckCircleIcon className={`${styles.ResultIcon} ${styles.DoneIcon}`} />
              </Tooltip>
            )}
            {status === 'invalid' && (
              <Tooltip label="Invalid">
                <ExclamationTriangleIcon className={`${styles.ResultIcon} ${styles.ErrorIcon}`} />
              </Tooltip>
            )}
            {status === 'warning' && (
              <Tooltip label="Warning">
                <QuestionMarkCircleIcon className={`${styles.ResultIcon} ${styles.WarningIcon}`} />
              </Tooltip>
            )}
            {status === 'outOfCompliance' && (
              <Tooltip label="Out Of Compliance">
                <ExclamationTriangleIcon className={`${styles.ResultIcon} ${styles.ErrorIcon}`} />
              </Tooltip>
            )}
          </div>
        )
      },
      {
        accessor: 'uploadedBy',
        title: 'Uploaded By',
        cellsClassName: styles.NameCell
      },
      {
        accessor: 'lastViewedAt',
        title: 'Last Viewed Date',
        sortable: false,
        render: ({ lastViewedAt }) => dayjs(new Date(lastViewedAt)).format('MM/DD/YYYY HH:mm A [EDT]')
      },
      {
        accessor: 'uploadedDate',
        title: 'Uploaded Date',
        sortable: false,
        render: ({ uploadedDate }) => dayjs(new Date(uploadedDate)).format('MM/DD/YYYY HH:mm A [EDT]')
      },
      {
        accessor: 'id',
        title: 'Actions',
        width: 100,
        sortable: true,
        render: (report) => (
          <div className={styles.ActionsWrapper}>
            {report.status === 'pending' ? (
              <Spinner width={34} />
            ) : (
              <>
                <ActionIcon
                  title="Download Report"
                  variant="transparent"
                  onClick={async () => onDownloadReport(report)}
                >
                  <DocumentArrowDownIcon
                    className={`${styles.ActionIcon} ${report.hasBeenDownloaded && styles.ClickedActionIcon}`}
                  />
                </ActionIcon>
                <ActionIcon variant="transparent" title="View Report" onClick={() => onViewReport(report)}>
                  <EyeIcon className={`${styles.ActionIcon} ${report.hasBeenViewed && styles.ClickedActionIcon}`} />
                </ActionIcon>
              </>
            )}
          </div>
        )
      }
    ],
    [onDownloadReport, onViewReport, onChangeName, onActionTaken, isEdit]
  )

  return {
    columns,
    reportss,
    setData,
    pagination: { page, setPage, pageSize, setPageSize },
    sorting: { sortStatus, setSortStatus },
    selectedReport,
    modalType,
    setModalType
  }
}
