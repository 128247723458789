import styles from './Topbar.module.css'
import Logo from '@/components/atoms/Logo/Logo'
import ToggleTheme from '@/components/atoms/ToggleTheme/ToggleTheme'
import UserMenu from '@/components/atoms/UserMenu/UserMenu'
import NotificationMenu from '@/components/atoms/NotificationMenu/NotificationMenu'
import { NAV_LINKS } from '@/enums/navLinks'
import { logoutUser, refreshSession } from '@/store/actions/auth.actions'
import { useAppDispatch, useAppSelector } from '@/store/hooks/store-hooks'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getNotification } from '@/store/actions/notification.action'
import { setNotification } from '@/store/slices/notification/notification.slice'
import { getReports } from '@/store/actions/reports.actions'

function Topbar() {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user)
  const notificationData = useAppSelector((state) => state.notificationData)
  const accessToken = useAppSelector((state) => state.auth.token)
  const [firstTimeCall, setFirstTimeCall] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [offset, setOffset] = useState<number>(0)
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(getNotification(offset, limit))
  }, [dispatch, offset, limit])
  useEffect(() => {
    if (firstTimeCall === 1) {
      getRealTimeNotification(accessToken)
      const firstTime = firstTimeCall
      setFirstTimeCall(firstTime + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])
  const getRealTimeNotification = async (accessToken: string | null) => {
    if (accessToken) {
      const eventSource = new EventSource(`${import.meta.env.VITE_API_URL}/api/v1/event/subscribe?token=${accessToken}`)
      eventSource.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data)
        console.log('Notification received:', data.message)
        dispatch(
          setNotification({
            message: data?.message,
            variant: 'success'
          })
        )
        dispatch(getReports('columnAccessor=id&direction=DESC'))
        dispatch(getNotification(0, 10))
      }

      eventSource.onerror = async (err) => {
        console.error(err, 'errerrerrerr')
        const refreshResponse = await dispatch(refreshSession()).unwrap()
        if (refreshResponse) {
          getRealTimeNotification(refreshResponse?.accessToken)
        }
        eventSource.close()
      }
      return () => {
        eventSource.close()
      }
    }
  }
  const handleLogout = async () => {
    try {
      await dispatch(logoutUser())
    } catch (error) {
      console.log('Error logging session out: ', error)
    } finally {
      navigate(NAV_LINKS.LOGIN, { replace: true })
    }
  }
  const handleLoadMore = () => {
    setLimit(10)
    setOffset(offset + 10)
  }
  return (
    <header className={`${styles.Topbar}`} data-testid="header-topbar">
      <div className={styles.Innertopbar}>
        <Logo alt="Luminous" width="240px" />
        <div className={styles.ActionWrapper}>
          {notificationData.notifications?.length > 0 && (
            <NotificationMenu
              notificatioData={notificationData}
              loadMore={() => {
                handleLoadMore()
              }}
            />
          )}
          <UserMenu user={user} logout={handleLogout} />
          <ToggleTheme />
        </div>
      </div>
    </header>
  )
}

export default Topbar
